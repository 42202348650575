<template>
  <div style="color: #000;height: 100%;background:#eeb601;" class="qr_code">
    <van-nav-bar style="background:#eeb601;color: #fff;" :title="shopname" left-arrow :fixed="true" :z-index="999" @click-left="back">
      <template #right>
        <van-icon @click="shuaxin" name="replay" size="18" />
      </template>
    </van-nav-bar>
    <div style="padding-top:46px;background:#eeb601;">
      <div class="mian">
        <div class="shoukuan">
          <div><img class="daizi" src="../../assets/img/daizi.png" alt />
            <span class="shou">二维码收款</span>
          </div>
          <div @click="tofenxiang"><van-icon name="share-o" />分享</div>
          <!-- <van-button icon="share" plain hairline type="warning" size="small">分享</van-button> -->
        </div>
        <!-- <div class="wxzfb">
          <div :class="zfriode==1?'zfactv':''" class="left wxlet" @click="bindqiehuan(1)">微信支付</div>
          <div :class="zfriode==3?'zfactv':''" class="left wxlet" @click="bindqiehuan(3)">支付宝</div>
        </div> -->
        <div class="ma">
          <div class="haoyou">无需加好友，扫二维码向我付钱</div>
          <div class="jiner">
            <span style="font-size: 16px;">￥</span>
            <span>{{form.amountActual?form.amountActual.toFixed(2):'0.00'}}</span>
          </div>
        </div>
        <div class="qy_code_wrapper">
          <vue-qr :text="downloadData.url" :margin="10" colorDark="#000" colorLight="#fff" :dotScale="1" :logoSrc="downloadData.icon" :logoScale="0.2" :size="200"></vue-qr>
        </div>
        <div class="anniua">
          <van-button type="warning" plain class="anniu" @click="setshaxin">刷新二维码</van-button>
          <van-button plain type="danger" class="anniu" @click="quxiao">取消二维码收款</van-button>
        </div>
      </div>
    </div>

    <van-popup v-model="show" class="heis">
      <van-nav-bar safe-area-inset-top title="结算确认" left-arrow :fixed="true" :z-index="999" @click-left="back">
      </van-nav-bar>
      <div class="tantops">
        <div class="tanjin">订单金额：<span style="color: red;font-size: 26px;">{{form.amountReceivable}}</span> 元</div>
        <div class="jina">
          减免<input class="jpiput" type="number" v-model="remission">元
        </div>
        <div class="jina">
          备注<input class="bzinpt" type="text" v-model="disReson">
        </div>
      </div>

      <van-button class="jsqr" type="danger" @click="serjsqe">结算确认</van-button>
    </van-popup>
  </div>
</template>

<script>
import orderModel from "../../api/order";
import utils from "../../assets/utils";
import {
  statusquery,
  alipayquery,
  fixpay,
  refresh,
  getDetailByOId,
  orderdis,
} from "../../api/check.js";
import vueQr from "vue-qr";
import { relativeTimeThreshold } from "moment";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  components: {
    vueQr,
  },
  // mixins: [preventBack], //注入
  data() {
    return {
      list: [],
      radio: "1",
      isiOS: false,
      disReson: "",
      form: {},
      zfriode: 1,
      loading: false,
      remission: 0,
      show: false,
      timers: null, //提成二维码定时器
      billNumbe: "",
      oid: "",
      totalMoney: "",
      gid: "",
      shopname: "",
      id: 0,
      downloadData: {},
    };
  },
  methods: {
    setupWebViewJavascriptBridge(callback) {
      if (window.WebViewJavascriptBridge) {
        return callback(window.WebViewJavascriptBridge);
      }
      if (window.WVJBCallbacks) {
        return window.WVJBCallbacks.push(callback);
      }
      window.WVJBCallbacks = [callback];
      let WVJBIframe = document.createElement("iframe");
      WVJBIframe.style.display = "none";
      WVJBIframe.src = "https://__bridge_loaded__";
      document.documentElement.appendChild(WVJBIframe);
      setTimeout(() => {
        document.documentElement.removeChild(WVJBIframe);
      }, 0);
    },
    //
    appSource() {
      var _this = this;
      const u = navigator.userAgent,
        app = navigator.appVersion;
      //const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
      this.isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    },
    tofenxiang() {
      var _this = this;
      var amountActual = this.form.amountActual?this.form.amountActual.toFixed(2):'0.00'
      var map = {
        'title':
          this.form.carNo +
          "(" +
          this.form.carBrand +
          ")" +
          '已经施工完成并交车，请付款。',
        'url':
          "https://shopping.car-posthouse.cn/userpay?gid=" +
          _this.gid +
          "&oid=" +
          _this.oid +
          "&billNumber=" +
          _this.billNumber,
        'description': "金额：" + amountActual,
        'image': "image_2",
      };
      let arr = JSON.stringify(map);
      if (_this.isiOS) {
        _this.setupWebViewJavascriptBridge((bridge) => {
          var navBack = bridge.callHandler(
            "ShareToWechat",
            arr,
            function responseCallback(responseData) {
              // alert('走了这里' + responseData)
              return responseData;
            }
          );
        });
      } else {
        window.androidjs.onCallbackIntent("ShareToWechat", arr, false);
      }
    },
    onLoad() {
      var _this = this;
      const data = {
        billNumber: this.form.billNumber,
        oid: this.oid,
        payType: this.zfriode,
        totalMoney: this.form.amountActual,
        gid: this.gid,
        type: 1,
      };
      fixpay(data)
        .then((e) => {
          if (e.code == 200) {
            // clearInterval(_this.timers);
            if (e.data.success == "true") {
              this.downloadData = {
                url: e.data.qrurl,
                icon: require("../../assets/img/1.png"), //二维码中间的图片,可以不设置
              };
            } else {
              _this.$toast(e.data.msg);
            }

            // if (this.zfriode == 1) {
            //   this.downloadData = {
            //     url: e.data.code_url,
            //     icon: require("../../assets/img/weixin.png") //二维码中间的图片,可以不设置
            //   };
            //   _this.getOrderstate(e.data.out_trade_no);
            // } else {
            //   this.downloadData = {
            //     url: e.data.code_url,
            //     icon: require("../../assets/img/zhifubao.png") //二维码中间的图片,可以不设置
            //   };
            //   _this.getOrderstatezhi(e.data.out_trade_no);
            // }
          } else {
            _this.zfriode = 1;
          }
        })
        .catch(() => loading.clear());
    },
    //刷新二维码
    setshaxin() {
      var _this = this;
      const data = {
        ordersn: this.form.billNumber,
        orderId: this.oid,
        price: this.form.amountActual,
        gid: this.gid,
        type: 101,
      };
      refresh(data).then((e) => {
        if (e.data.success == "true") {
          this.downloadData = {
            url: e.data.qrurl,
            icon: require("../../assets/img/1.png"), //二维码中间的图片,可以不设置
          };
          _this.$toast.success("刷新成功");
        } else {
          _this.$toast(e.data.msg);
        }
      });
    },
    getOrderstate(out_trade_no) {
      //判断支付是否成功，监听微信支付是否成功
      console.log(out_trade_no);
      let _this = this;
      let num = 0;
      _this.timers = setInterval(() => {
        num++;
        statusquery({ out_trade_no: out_trade_no }).then((res) => {
          console.log(res);
          console.log(res.code);
          if (res.code == 200) {
            if (res.data.trade_state == "SUCCESS") {
              clearInterval(_this.timers);
              // this.$message.success("支付成功");
              _this.$toast({
                message: "支付成功",
                type: "success",
                duration: 3000,
              });
              setTimeout(() => {
                window.androidjs.onCallbackIntent("Finish");
              }, 3000);
            }
          }
        });
        if (num == 90) {
          (this.ispay = false),
            //别忘了清除定时器
            clearInterval(this.timers);
        }
      }, 2500);
    },
    getOrderstatezhi(out_trade_no) {
      //判断支付是否成功，监听微信支付是否成功
      console.log(out_trade_no);
      let _this = this;
      let num = 0;
      _this.timers = setInterval(() => {
        num++;
        alipayquery({ outTradeNo: out_trade_no }).then((res) => {
          console.log(res);
          console.log(res.code);
          if (res.code == 200) {
            if (res.data.trade_state == "SUCCESS") {
              clearInterval(_this.timers);
              // this.$message.success("支付成功");
              _this.$toast({
                message: "支付成功",
                type: "success",
                duration: 3000,
              });
              setTimeout(() => {
                window.androidjs.onCallbackIntent("Finish");
              }, 3000);
            }
          }
        });
        if (num == 90) {
          (this.ispay = false),
            //别忘了清除定时器
            clearInterval(this.timers);
        }
      }, 2500);
    },
    bindqiehuan(row) {
      this.zfriode = row;
      this.onLoad();
    },
    quxiao() {
      var _this = this;
      const data = {
        oid: this.oid,
      };
      orderModel
        .onlinePay(data)
        .then((e) => {
          _this.$toast("取消成功");
          setTimeout(() => {
            _this.back();
          }, 600);
        })
        .catch(() => loading.clear());
    },
    //结算确认
    serjsqe() {
      var _this = this;
      var data = {
        oid: this.oid,
        disReson: this.disReson,
        amount: this.remission,
      };
      orderdis(data).then((res) => {
        if (res.code == 200) {
          // this.form = res.data;
          _this.getDetailByOId();
        }
      });
    },

    shuaxin() {
      history.go(0);
    },
    setqueding() {
      this.onLoad();
    },
    setquxiao() {
      this.number = 0;
      this.onLoad();
    },
    getlist() {
      var _this = this;
      var data = {
        oid: this.oid,
        gid: this.gid,
      };
      getDetailByOId(data).then((res) => {
        if (res.code == 200) {
          _this.form = res.data;
          if (res.data.status != 7) {
            _this.show = true;
          } else {
            _this.onLoad();
            _this.show = false;
          }
        }
      });
    },
    back() {
      // window.androidjs.onCallbackIntent("Finish");
      var _this = this;
      if (this.isiOS) {
        // alert('走了这里')
        // history.go(-1)
        _this.setupWebViewJavascriptBridge((bridge) => {
          var navBack = bridge.callHandler(
            "navBack",
            null,
            function responseCallback(responseData) {
              // alert('走了这里' + responseData)
              return responseData;
            }
          );
        });
      } else {
        window.androidjs.onCallbackIntent("Finish");
      }
    },
  },
  created() {
    var token = utils.getUrlKey("token");
    this.billNumbe = utils.getUrlKey("billNumbe");
    this.oid = utils.getUrlKey("oid");
    this.totalMoney = utils.getUrlKey("totalMoney");
    this.gid = utils.getUrlKey("gid");
    this.shopname = utils.getUrlKey("shopname");
    localStorage.setItem("Authorization", token);
    this.appSource();
    this.getlist();
    return;
  },
  mounted() {},
  beforeDestroy() {
    //跳转页面时关闭定时器
    if (this.timers) {
      clearInterval(this.timers);

      // console.log('asdasdsad')
    }
    console.log("执行了");
  },
};
</script>

<style lang="less" scoped>
.wxzfb {
  width: 100%;
  margin: auto;
  line-height: 35px;
  height: 35px;
  background-color: #f8f9f9;
  margin-bottom: 10px;
  /* #07c160 */
}
.zfactv {
  background-color: #07c160;
  color: #fff;
}
.wxlet {
  width: 50%;
  text-align: center;
}
.mian {
  margin: 50px 12px;
  background: #fff;

  border-radius: 8px;
  font-size: 14px;
  color: #666;
  padding-bottom: 25px;
}
.shoukuan {
  color: #eeb601;
  padding: 0 15px;
  border-radius: 8px 8px 0 0;
  height: 50px;
  line-height: 50px;
  background: #e5e8e8;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ma {
  padding: 0 15px;
  text-align: center;
}
.daizi {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}
.shou {
  margin-left: 4px;
  vertical-align: middle;
}
.haoyou {
  margin-top: 15px;
  line-height: 40px;
}
.jiner {
  margin-top: 5px;
  font-weight: bold;
  color: #000;
  font-size: 28px;
  font-stretch: ultra-condensed;
}
.qy_code_wrapper {
  padding: 0 15px;
  text-align: center;
}

.inptt {
  padding: 15px 0;
  font-size: 26px;
  margin: 0 20px;
  background: #fff;
  border-bottom: 1px solid #f5f5f5;
}
.icpt {
  margin-left: 7px;
  display: inline-block;
  width: 80%;
  border: none;
}
.anniua {
  text-align: center;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
}
.anniu {
  width: 44%;
}
.jina {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px;
}
.tantops {
  margin-top: 200px;
}
.tanjin {
  text-align: center;
}
.jpiput {
  line-height: 28px;
  border: 1px solid #dcdcdc;
  padding: 0 12px;
  width: 80px;
  margin-left: 12px;
  margin-right: 10px;
}
.bzinpt {
  line-height: 28px;
  border: none;
  border-bottom: 1px solid #dcdcdc;
  padding: 0 12px;

  margin-left: 12px;
  margin-right: 10px;
  color: #666;
}
.van-popup {
  width: 100%;
  height: 100%;
}
.jsqr {
  width: 70%;
  margin: auto;
  margin-top: 140px;
  display: block;
}
</style>